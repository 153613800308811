export const PublicAccessRoutes: string[] = [
  '/dashboard',
  '/learn',
  '/events',
  '/community',
  '/tools',
  '/solutions',
  '/sign-up',
  '/initiatives'
];
